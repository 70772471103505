// See: https://mui.com/customization/theming/

import { createTheme } from '@mui/material/styles';
import shadows, { Shadows } from '@mui/material/styles/shadows';

const MaterialTheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#000000',
        },
        secondary: {
            main: '#6C2DDD',
        },
    },
    shadows: Array(shadows.length).fill('none') as Shadows,
    components: {
        MuiButton: {
            defaultProps: {
                variant: 'contained',
                disableRipple: false,
            },
        },
        MuiTextField: {
            defaultProps: {
                variant: 'outlined',
            },
        },
        MuiAlertTitle: {
            styleOverrides: {
                root: {
                    fontFamily: 'Roboto',
                },
            },
        },
        MuiAlert: {
            defaultProps: {
                variant: 'filled',
            },
            styleOverrides: {
                root: {
                    fontFamily: 'Roboto',
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    fontFamily: 'Roboto',
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    fontFamily: 'Cascadia Code',
                    fontSize: 12,
                    backgroundColor: '#fbfbfb',
                },
            },
        },
    },
    typography: {
        h4: {
            fontWeight: 600,
        },
        button: {
            fontFamily: 'Roboto'
        },
        fontFamily: [
            'Lato',
            'Arial',
            'sans-serif'
        ].join(','),
    },
});

export default MaterialTheme;

import React from 'react';
import _html from "./header.html";

var html = { __html: _html };

export default class WpHeader extends React.Component {
    render() {
        return (
            <div dangerouslySetInnerHTML={html} />
        )
    }
}

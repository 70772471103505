import Typography from '@mui/material/Typography';
import { Component } from 'react';
import './App.css';
import JsonUploader from './components/JsonUploader';
import WpHeader from './wp-header/WpHeader';

export default class App extends Component {
    render() {
        return (
            <div className='app'>
                <WpHeader />
                <main>
                    <Typography variant='h4' component='h2'>
                        Create a Web Walkly
                    </Typography>
                    <div className='content'>
                        <JsonUploader />
                    </div>
                </main>
            </div>
        );
    }
}

import Editor from '@monaco-editor/react';
import { editor } from 'monaco-editor/esm/vs/editor/editor.api';
import * as Icons from '@mui/icons-material';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Snackbar from '@mui/material/Snackbar';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { ChangeEvent, Component, useState } from 'react';
import { api, host } from '../env';

const Input = styled('input')({ display: 'none' });

type JsonEditorProps = {
    json: string;
    changeHandler: (value: string) => void;
}

class JsonEditor extends Component<JsonEditorProps> {

    options: editor.IStandaloneEditorConstructionOptions = {
        fontFamily: 'JetBrains Mono',
        fontSize: 12,
        lineHeight: 20,
        fontLigatures: true,
        renderWhitespace: 'all',
        tabSize: 4,
        minimap: { enabled: false },
        overviewRulerLanes: 0,
        scrollbar: {
            useShadows: false,
            verticalScrollbarSize: 7.5,
        },
        scrollBeyondLastLine: false,
    };

    render() {
        return (
            <div className='json-editor'>
                <Editor
                    options={this.options}
                    height="60vh"
                    defaultValue={this.props.json}
                    language="json"
                    theme="vs-dark"
                    onChange={this.props.changeHandler}
                />
            </div>
        )
    }
}

function JsonUploader() {
    const [file, setFile] = useState<File>();
    const [hasFile, setHasFile] = useState(false);
    const [json, setJson] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [result, setResult] = useState({ url: '' });
    const [statusCode, setStatusCode] = useState(0);

    const editorChangeHandler = (value: string) => setJson(value);

    const fileInputChangeHandler = (changeEvent: ChangeEvent<HTMLInputElement>) => {
        let file = changeEvent.target.files[0];

        const reader = new FileReader();
        reader.onload = (event: ProgressEvent<FileReader>) => {
            const content = (event.currentTarget as any).result;
            setFile(file);
            setJson(content);
            setHasFile(true);
        };

        reader.readAsText(file);
    };

    const deleteHandler = () => {
        setFile(undefined);
        setHasFile(false);
        setJson('');
    };

    async function postStory(body: string, doOverwrite: Boolean = false) {
        let endpoint = `${api}/story/`;

        const queryParams = new URLSearchParams();

        if (doOverwrite) {
            queryParams.append('force', `${doOverwrite}`);
            endpoint += '?' + queryParams;
        }

        return await fetch(
            endpoint,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body,
            },
        );
    }

    async function handleResult(response: Response) {
        setIsLoading(false);

        const status = response.status;

        const apiResult = await response.json();
        setResult(apiResult);

        if (status >= 400) {
            setErrorMessage(apiResult.message);
        }

        setTimeout(() => setErrorMessage(''), 5000);

        setStatusCode(status);
    }

    const submitHandler = async () => {
        setIsLoading(true);
        const response = await postStory(json);

        setTimeout(async () => await handleResult(response), 500);
    };

    const submitOverwriteHandler = async () => {
        setStatusCode(0);
        setErrorMessage('');
        setIsLoading(true);
        const response = await postStory(json, true);

        setTimeout(async () => await handleResult(response), 500);
    };

    if (statusCode > 200 && statusCode < 400) {
        return (
            <div>
                <Alert severity="success">
                    <AlertTitle>Success</AlertTitle>
                    HTML File for Web Walkly created:
                    <code><a href={`${host}/${result.url}/`}
                             target="_blank"
                             rel="noopener noreferrer">{result.url}</a></code>.
                </Alert>
            </div>
        );
    }

    return (
        <div>
            {/* Upload Button */}
            {!hasFile && (
                <label htmlFor="upload-btn">
                    <Input
                        id="upload-btn"
                        type="file"
                        accept="application/JSON"
                        onChange={fileInputChangeHandler} />
                    <Button
                        component="span"
                        disableElevation
                        startIcon={<Icons.UploadFile />}>
                        Upload JSON file
                    </Button>
                </label>
            )}

            {/* Editor / Hint */}
            <div className='editor'>
                <Typography variant="body1" component="div">
                    {hasFile
                        ? (
                            <div>
                                <Chip
                                    label={file.name}
                                    variant="outlined"
                                    onDelete={deleteHandler} />
                                <JsonEditor
                                    changeHandler={editorChangeHandler}
                                    json={json} />
                            </div>
                        )
                        : <p>Start by uploading a json file.</p>
                    }
                </Typography>
            </div>

            {/* Submit Button */}
            {hasFile && (
                <Button
                    color="secondary"
                    hidden={errorMessage !== '' && statusCode === 409}
                    endIcon={<Icons.ArrowCircleRightOutlined />}
                    onClick={submitHandler}>
                    Create Web Walkly
                </Button>
            )}

            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={errorMessage !== ''}>
                <Alert severity="error">
                    <AlertTitle>Error</AlertTitle>
                    {errorMessage}
                    {statusCode === 409 && (
                        <div>
                            Web Walkly
                            <code><a href={`${host}/${errorMessage}/`}
                                     target="_blank"
                                     rel="noopener noreferrer">{errorMessage}</a></code>
                            already exists.
                            <br /><br />
                            <Button
                                color="warning"
                                endIcon={<Icons.ArrowCircleRightOutlined />}
                                onClick={submitOverwriteHandler}>
                                Overwrite It
                            </Button>
                        </div>
                    )}
                </Alert>
            </Snackbar>

            <Backdrop
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}>
                <CircularProgress color="secondary" />
            </Backdrop>
        </div>
    )
}

export default JsonUploader;
